import i18next from 'i18next';
import { FAQ_DE, FAQ_EN } from '../config/constants';

export const isDeLanguage = () => i18next.language === 'de';

export const getFaqLink = () => {
  return isDeLanguage() ? FAQ_DE : FAQ_EN;
};
export const buildLocalizedLink = (url: string) => {
  return `${isDeLanguage() ? '' : `/${i18next.language}`}${url}`;
};
