export const INSTAGRAM_URL = 'https://www.instagram.com/splintinvest/';
export const FACEBOOK_URL = 'https://www.facebook.com/splintinvest/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/splintinvest/';
export const APP_DOWNLOAD_LINK = 'https://api.splintinvest.com/api/home/get-the-app/';
export const VIDEO_URL = 'https://youtu.be/e-0kTUE0-ns';
export const FAQ_DE = '/blog/so-funktioniert-splint';
export const FAQ_EN = '/en/blog/how-splint-works';

export enum Sections {
  blogSection = 'blog',
  whatIsSplint = 'what-is-splint',
  howTo = 'how-to',
}
