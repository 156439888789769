import { Global, css } from '@emotion/react';
import { baseFontSize, Breakpoint, fontFamily } from '../../config/styles';
import FreightTextProBoldWoff2 from '../../fonts/FreightTextProBold/FreightTextProBold-Regular.woff2';
import FreightTextProBoldWoff from '../../fonts/FreightTextProBold/FreightTextProBold-Regular.woff';
import ElidoWoff2 from '../../fonts/Elido/Elido-Bold.woff2';
import ElidoWoff from '../../fonts/Elido/Elido-Bold.woff';
import ElidoBookWoff2 from '../../fonts/Elido/Elido-Book.woff2';
import ElidoBookWoff from '../../fonts/Elido/Elido-Book.woff';
import Header from '../Header';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Global
        styles={css`
          @font-face {
            font-family: ${fontFamily.freightText};
            src: url(${FreightTextProBoldWoff2}) format('woff2'), url(${FreightTextProBoldWoff}) format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: ${fontFamily.elidoBook};
            src: url(${ElidoBookWoff2}) format('woff2'), url(${ElidoBookWoff}) format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: ${fontFamily.elido};
            src: url(${ElidoWoff2}) format('woff2'), url(${ElidoWoff}) format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }

          html,
          body {
            -webkit-text-size-adjust: 100%;
            font-size: ${baseFontSize};
            font-optical-sizing: auto;

            ${Breakpoint.tablet} {
              font-size: 14px;
            }
            ${Breakpoint.mobile} {
              font-size: 10px;
            }
          }

          body {
            margin: 0px;
            font-variant: lining-nums;
            line-height: 1;
          }
        `}
      />
      <Header />
      {children}
    </>
  );
};

export default Layout;
