import CloseIcon from '../../images/close.svg';
import IconButton from '../IconButton';
import { css } from '@emotion/react';
import { Breakpoint, colorPalette, commonStyles, fontFamily, fontSize, spacing, zIndex } from '../../config/styles';
import { useEffect } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { buildLocalizedLink, getFaqLink } from '../../utils/urls';
import { Sections } from '../../config/constants';
import { Link } from 'gatsby';
const styles = {
  specificArea: (area: string) => css`
    grid-area: ${area};
    z-index: ${zIndex.positive};
  `,
  menu: css`
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${colorPalette.white};
    overflow: scroll;

    &:before {
      content: '';
      background: ${colorPalette.lightGreen};
      position: absolute;
      height: 100%;
      width: 50%;

      ${Breakpoint.tablet} {
        display: none;
      }
    }
  `,
  languageBar: css`
    padding: ${spacing['20']} ${spacing['40']};
    grid-area: language-bar;
    font-size: ${fontSize['24']};
    font-family: ${fontFamily.elido};
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: ${spacing['20']};

    ${Breakpoint.tablet} {
      justify-content: start;
    }
    a {
      color: ${colorPalette.main};
      text-decoration: none;
    }
  `,
  menuWrapper: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    grid-template-areas:
      '. header'
      'title language-bar'
      'first-row-left first-row-right'
      'second-row-left second-row-right';

    ${Breakpoint.tablet} {
      grid-template-columns: 1fr;
      grid-template-areas:
        'header'
        'title'
        'language-bar'
        'first-row-left'
        'first-row-right'
        'second-row-left'
        'second-row-right';
    }
  `,
  title: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['72']};
    color: ${colorPalette.main};
    margin: 0;
    padding: ${spacing['20']} ${spacing['40']};
    grid-area: title;
  `,

  menuItem: css`
    gap: ${spacing['20']};
    grid-template-columns: 4.5rem 1fr;

    text-decoration: none;
    display: grid;
    padding: ${spacing['60']};
    grid-template-areas:
      'number title'
      '. text';

    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
  `,
  menuItemNumber: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['72']};
    color: ${colorPalette.main};
    grid-area: number;
    text-align: center;
  `,
  menuItemTitle: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['48']};
    color: ${colorPalette.main};
    margin: 0;
    display: flex;
    align-items: end;
  `,

  menuItemText: css`
    ${commonStyles.basicText}
    font-size: ${fontSize['24']};
    grid-area: text;
  `,

  closeButton: css`
    padding-top: ${spacing['20']};
    padding-right: ${spacing['20']};
    justify-content: flex-end;
    display: flex;
  `,
};
const HeaderMenu = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslation();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.body.style.overflowX = 'hidden';

    return () => {
      document.body.style.overflowY = 'scroll';
      document.body.style.overflowX = 'auto';
    };
  });

  return (
    <div css={styles.menu}>
      <div css={styles.menuWrapper}>
        <div css={[styles.specificArea('header'), styles.closeButton]}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <h2 css={[styles.specificArea('title'), styles.title]}>{t('header_menu_title')}</h2>
        <div css={styles.languageBar}>
          <Link to="/en/" onClick={onClose}>
            {t('footer_lang_english')}
          </Link>
          <Link to="/" onClick={onClose}>
            {t('footer_lang_german')}
          </Link>
        </div>
        <a
          css={[styles.menuItem, styles.specificArea('first-row-left')]}
          href={buildLocalizedLink(`/#${Sections.howTo}`)}
          onClick={onClose}
        >
          <div css={styles.menuItemNumber}>1</div>
          <h4 css={styles.menuItemTitle}>{t('header_menu_item_1_title')}</h4>
          <div css={styles.menuItemText}>{t('header_menu_item_1_text')}</div>
        </a>
        <a
          css={[styles.menuItem, styles.specificArea('first-row-right')]}
          href={buildLocalizedLink(`/#${Sections.whatIsSplint}`)}
          onClick={onClose}
        >
          <div css={styles.menuItemNumber}>2</div>
          <h4 css={styles.menuItemTitle}>{t('header_menu_item_2_title')}</h4>
          <div css={styles.menuItemText}>{t('header_menu_item_2_text')}</div>
        </a>
        <a
          css={[styles.menuItem, styles.specificArea('second-row-left')]}
          href={buildLocalizedLink(`/#${Sections.blogSection}`)}
          onClick={onClose}
        >
          <div css={styles.menuItemNumber}>3</div>
          <h4 css={styles.menuItemTitle}>{t('header_menu_item_3_title')}</h4>
          <div css={styles.menuItemText}>{t('header_menu_item_3_text')}</div>
        </a>
        <a css={[styles.menuItem, styles.specificArea('second-row-right')]} href={getFaqLink()}>
          <div css={styles.menuItemNumber}>4</div>
          <h4 css={styles.menuItemTitle}>{t('header_menu_item_4_title')}</h4>
          <div css={styles.menuItemText}>{t('header_menu_item_4_text')}</div>
        </a>
      </div>
    </div>
  );
};

export default HeaderMenu;
