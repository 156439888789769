import ButtonLink, { ButtonSizeVariant } from '../ButtonLink';
import { css } from '@emotion/react';
import {
  Breakpoint,
  colorPalette,
  containerSpacing,
  headerHeight,
  mobileHeaderHeight,
  spacing,
  zIndex,
} from '../../config/styles';
import IconButton from '../IconButton';
import BurgerIcon from '../../images/burger.svg';
import { useState } from 'react';
import HeaderMenu from './HeaderMenu';
import { useTranslation } from '../../hooks/useTranslation';
import { APP_DOWNLOAD_LINK } from '../../config/constants';

const styles = {
  headerWrapper: css`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: ${zIndex.header};
  `,
  header: css`
    background-color: ${colorPalette.lightBlue};
    padding-top: ${headerHeight};
    ${Breakpoint.mobile} {
      padding-top: ${mobileHeaderHeight};
    }
  `,
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${containerSpacing};
    ${Breakpoint.mobile} {
      margin: 10px;
    }
  `,
  rightSideButtons: css`
    display: flex;
    flex-direction: column;
    gap: ${spacing['20']};
  `,
};
const Header = () => {
  const t = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <header css={styles.header}>
      <div css={styles.headerWrapper}>
        <div css={styles.wrapper}>
          <div css={styles.rightSideButtons}>
            <IconButton
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <BurgerIcon />
            </IconButton>
          </div>
          <ButtonLink
            href={APP_DOWNLOAD_LINK}
            label={t('common_btn_download_app')}
            size={ButtonSizeVariant.small}
            internal={false}
          />
        </div>
        {isMenuOpen && <HeaderMenu onClose={() => setMenuOpen(false)} />}
      </div>
    </header>
  );
};

export default Header;
