import { TOptions } from 'i18next';
import { useCallback } from 'react';
import { useTranslation as use18nextTranslation } from 'react-i18next';

import { MessageKey } from '../utils/i18n';

export const useTranslation = () => {
  const { t } = use18nextTranslation();
  const translate = useCallback((key: MessageKey, options?: TOptions): string => t(key, options), [t]);
  return translate;
};
