import { css } from '@emotion/react';
import React from 'react';
import { borderRadius, colorPalette, fontFamily, fontSize, spacing } from '../../config/styles';
import { Link } from 'gatsby';

export enum ButtonSizeVariant {
  small = 'small',
  medium = 'medium',
}

const styles = {
  wrapper: (size: ButtonSizeVariant) => css`
    background-color: ${colorPalette.lightGreen};
    text-decoration: none;
    color: ${colorPalette.main};
    border-radius: ${borderRadius['30']};
    font-family: ${fontFamily.elido};
    display: inline-block;

    ${size === ButtonSizeVariant.small &&
    css`
      font-size: ${fontSize['18']};
      padding: ${spacing['10']} ${spacing['30']};
    `}

    ${size === ButtonSizeVariant.medium &&
    css`
      font-size: ${fontSize['24']};
      padding: ${spacing['10']} ${spacing['40']};
    `}
    
    &:hover {
      background-color: ${colorPalette.lightGreen70};
    }
  `,
};
const ButtonLink = ({
  href,
  label,
  internal,
  size = ButtonSizeVariant.medium,
}: {
  href: string;
  label: string;
  internal: boolean;
  size?: ButtonSizeVariant;
}) => {
  if (internal) {
    return (
      <Link css={styles.wrapper(size)} to={href}>
        {label}
      </Link>
    );
  }
  return (
    <a css={styles.wrapper(size)} href={href}>
      {label}
    </a>
  );
};

export default ButtonLink;
